<template>
    <div class="act-content">
      <div class="page-box">
        <div class="page-content">
          <div class="desc">{{$parent.game_info.name}}云游戏，随地随玩</div>
          <div class="title"></div>
          <div class="collect">
            <div class="item" @click="CollectionUrl">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANKSURBVHgB7ZuLcdswDIbhXgfICOwEcSawM4nVCZJOYGeCNhPYnaDpBEonSDqBtUG8AQocqVpx6FCU+IAu/O5wcmzKEv+AIAHKAIVCoVAoFAofkxl4gogXdFiRXcC0OJD9ns1mjc9JXgKROIoOTzA9cVoasmsfkT6BH2uYrjiMAt2H3vgKpGD6KJ/GvgJ9OIpADj7DeB4p6F2DQGhSqemwhBEUD3JQBHJQBHJQBHIgSiBeqZtURgzSPKgiuwFBiBHI5HmcBN9K8iJJHlSBTgNYHDFeJEKgjve0iPEiKR60hNdJpBgvkiKQrQQhwouyC0QiVGAvQYjwomwCmTXPHN4vYLEXLXN6Uohs/iymY4qMhbgE7RVzOM5WLrhNbb6La8qNsWeyv6DrzM9UTTiABLh8gG+p32m/xvisQ92vjahDjP6zd3TYQDw25hrRiB6DIooUXRwmSZCOIFIScZhks1hAkZKJwySd5gOIlFQcJvk6yHTwAfx5SC0Ok2uhOJnd2VwCzcGfIeeMJrlAqFfXQzwoSzk2hweN8YTkXpRDoMsz7zegq4pfzWufc6ORQyB18jcnmhuyK5qlfpLtyL6AXSgFiYmazZ+hHSYszA+ye1s2zkJRzHkEXYqt4FgVkEuI7Jja71Fn+b0DLura0XeyF/AgxP3m8KAr3/qNeWTuG3XunoVNWf9JLtCYzvk+gBmCsjfvoAjkoAjkIEeQtmJmtVuyPdmfHPHGRnaBUG87V6D3wNqp/0Dvc0nkLrdQOffFlmZNwh5z+oA6v674M2qz5baQiRzZfCtM3ydQK25L5zyRrSAxSQTi+EK2MSvhoY/mcpqxMyvxlRma0Umxs8qBtxtfxqLIdmSNydWixqkoHtQZRuwxfX8A02b1Ozhf7uiiQEic+o8r+WuFQT9eTpNXk5xWZjj5UGMnTmGAZDWEQBw8NwM7s+hxzcpcw4c96jhV264LsUB/77BRYw9hLNdeoB5Ko68PscDhArXDSMFIUA+/rfnOyQv0Jr4EvJfBcQpi4SEQt0u2qPMUKqtA/Ll3fAl4fwt0x6nkAvEw4nqxAiHgMU4lF+jXiTBrFPbjky5GqBt8PfyiCtTuLqwkC2MDdZzaSvL0QqFQKBTi8g+XIMDExPTRAAAAAABJRU5ErkJggg=="
              />
              添加桌面快捷方式
            </div>
          </div>
          <div class="swiper-box">
            <el-carousel type="card" :interval="5000" height="120px">
              <el-carousel-item v-for="(item, index) in $parent.game_info.images" :key="index">
                <img  :src="item.url" style="width: 100%;height:120px;"/>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <div class="footer">
        <!-- <div v-if="$parent.game_info.login_info.login == 0" class="vip flex xc yc visibility">
          <img
            class="icon-vip"
            src="../../../../public/static/cloud/img/s/vip.png"
          />
          登录可获得更多时长，与特权
          <span  @click="$parent.Login">登录</span>
        </div>
        <div v-if="$parent.game_info.login_info.login == 1" class="vip flex xc yc visibility">
          <img
            class="icon-vip"
            src="../../../../public/static/cloud/img/s/vip.png"
          />
          <span  @click="$parent.Personal">个人中心</span>
        </div> -->
        <div class="foot">
          <div class="foot-box">
            <button class="start-btn s-0 m-1" @click="$parent.GameStart"> 
              <span class="text">启动游戏</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import "swiper/dist/js/swiper";
  import "swiper/dist/css/swiper.css";
  export default {
    name: "GameMoHomeModel",
    data() {
      return {};
    },
    mounted() {
      
    },
    methods: {
      //发送到手机桌面
      CollectionUrl(){
        var url = window.location.href;
        var name = this.$parent.game_info.name;
        try {
        // eslint-disable-next-line no-undef
        var WshShell = new ActiveXObject("WScript.Shell");
        var oUrlLink = WshShell.CreateShortcut(WshShell
            .SpecialFolders("Desktop")
            + "\\" + name + ".url");
        oUrlLink.TargetPath = url;
        oUrlLink.Save();
        this.$message({
          type: "success",
          message: "成功创建桌面快捷方式!",
        });
      } catch (e) {
        this.$message({
          message: "当前浏览器不支持此功能",
          type: "warning",
        });
      }
      }
    },
  };
  </script>
  
  <style scoped lang="less">
  .act-content {
    width: 100%;
    min-height: 100%;
    background-color: #0f1a21;
    background-image: url(../../../../public/static/cloud/img/m/bg.jpg);
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    position: relative;
    .footer {
      padding-top: 7px;
      background-color: #122028;
      margin-top: 12px;
      height: 108px;
      .vip {
        margin: 0 auto 10px;
        display: flex;
        white-space: nowrap;
        color: #ffffffb3;
        visibility: hidden;
        .icon-vip {
          width: 19px;
          margin-right: 8px;
        }
        .icon-arrows {
          width: 16px;
        }
        span {
          margin-left: 8px;
          display: flex;
          align-items: center;
          color: #ffc83c;
        }
      }
      .vip.visibility {
        visibility: visible;
      }
      .foot {
        padding: 0 14px;
        font-size: 12px;
        .foot-box {
          box-shadow: 0 0 10px #0003;
          -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
          border-radius: 7px;
          margin: 0 auto;
          font-size: 12px;
          z-index: 9;
          display: flex;
          justify-content: center;
          .start-btn {
            background-image: url(../../../../public/static/cloud/img/m/start-btn.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: auto 100%;
            background-color: unset;
            margin-top: -1px;
            margin-bottom: -1px;
            width: 196px;
            height: 40px;
            padding: 0;
            border: none;
            color: #fff;
            font-weight: 500;
            font-size: 15px;
            line-height: 21px;
            cursor: pointer;
            margin-right: -7px;
            span {
              display: block;
            }
          }
        }
      }
    }
    .page-box {
      width: 100%;
      margin: 0 auto;
      padding-top: 176px;
      .page-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        .description {
          position: relative;
          margin: 16px auto 0;
          width: 343px;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          font-family: PingFang SC, Helvetica, Avenir, Arial, sans-serif;
          color: #fff;
          .more {
            display: none;
            position: absolute;
            right: 0;
            bottom: 0;
            background: #0f1a21;
            align-items: center;
            color: #fff9;
            img {
              width: 12px;
              margin-left: 2px;
            }
          }
        }
        .description.hide {
          height: 42px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          .more {
            display: flex;
          }
        }
        .swiper-box {
          margin-top: 35px;
          width: 100%;
          overflow: hidden;
          .swiper-content {
            width: 343px;
            margin: 0 auto;
            #swiper-view {
              .swiper-slide {
                img {
                  width: 100%;
                }
              }
            }
          }
        }
        .logo {
          width: 304px;
        }
        .desc {
          margin-top: 8px;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #fff;
        }
        .title {
          background-size: contain;
          background-image: url(../../../../public/static/cloud/img/m/title-00.png);
          background-repeat: no-repeat;
          background-position: center;
          margin-top: 8px;
          width: 323px;
          max-width: 100%;
          height: 60px;
        }
        .collect {
          margin-top: 20px;
          display: flex;
          align-items: center;
          .item {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 13px;
            margin-right: 32px;
            font-family: PingFang SC, Helvetica, Avenir, Arial, sans-serif;
            color: #fff;
            img {
              width: 18px;
              height: 18px;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
  </style>