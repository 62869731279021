import { render, staticRenderFns } from "./GameMoGameModel.vue?vue&type=template&id=25b0b10a&scoped=true&"
import script from "./GameMoGameModel.vue?vue&type=script&lang=js&"
export * from "./GameMoGameModel.vue?vue&type=script&lang=js&"
import style0 from "./GameMoGameModel.vue?vue&type=style&index=0&lang=less&v-if=%24parent.info.module%20%3D%3D%20'game'&"
import style1 from "./GameMoGameModel.vue?vue&type=style&index=1&id=25b0b10a&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25b0b10a",
  null
  
)

export default component.exports