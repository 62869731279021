<template>
  <div id="Home" v-loading="Loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)">
     <game-mo-home-model v-show="info.module == 'home'"/>
     <game-mo-game-model ref="GameMoGame" v-show="info.module == 'game'"/>
     <login-mo-model ref="LoginGame"/>
     <personal-mo-model ref="PersonalMo"/>
   </div>
 </template>
 
 <script>
 import GameMoHomeModel from './components/GameMoHomeModel'
  import GameMoGameModel from './components/GameMoGameModel'
  import LoginMoModel from './components/LoginMoModel'
  import PersonalMoModel from './components/PersonalMoModel'
 import Vue from "vue";
 export default {
   name: "mobilegame",
   components: {
     GameMoHomeModel,
     GameMoGameModel,
     LoginMoModel,
     PersonalMoModel,
   },
   data () {
     return {
       Loading: false,
       info: {
         module: 'home',
       },
       //游戏基本信息
       game_info:{
        login_info:{
          login:0,
          user:"",
        },
         user: "",
         user_img: "",
         name: "",
         qroup: "",
         address: "",
         registered: "",
         user_length: 0,
         game_length: 0,
         task: [],
         weixurl: "",
         authorization: "",
         phone_url: "",
         length:0,
       },
       //云端配置
       cloud_config: {
         token: "",
         address: "wss://raystream-region-sc-fs.doulong.cn",
         iceServers: [
           {
             urls: "turn:region-sc-fs-gateway.doulong.cn:3478",
             username: "coturn",
             credential: "123456",
           },
         ],
       },
     };
   },
   created () {
    this.isMobile();
     document.title = "云游戏";
     this.GetCloud(); //获取基本配置
   },
   methods: {
    isMobile() {
      var flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      if (flag) {
        this.$router.replace('/cloud/mobilegame?key='+this.$route.query.key);
      } else {
        this.$router.replace('/cloud?key='+this.$route.query.key);
      }
    },
     //获取游戏基本数据
     GetCloud(){
       this.Loading = true;
         this.axios({
           // 默认请求方式为get
           method: "post",
           url: Vue.prototype.config.weburl + "/cloud/getCloud",
           // 传递参数
           data: {
             key: this.$route.query.key,
             token: localStorage.getItem("token"),
           },
           responseType: "json",
         })
           .then((response) => {
             this.Loading = false;
             if (response.data.code == 200) {
               this.game_info = response.data.info;
               document.title = response.data.info.name + "-云游戏";
             } else {
               this.$message({
                 message: response.data.msg,
                 type: "warning",
               });
             }
           })
           .catch((error) => {
             this.Loading = false;
             // 请求失败，
             console.log(error);
           });
     },
     //设置登陆token
     setToken (token) {
       localStorage.token = token; //同步存储token至localStorage
     },
     //启动游戏
     GameStart(){
       if (this.Loading == true) {
           return false;
         }
         this.Loading = true;
         this.axios({
           // 默认请求方式为get
           method: "post",
           url: Vue.prototype.config.weburl + "/cloud/CloudStart",
           // 传递参数
           data: {
             key: this.$route.query.key,
             token: localStorage.getItem("token"),
           },
           responseType: "json",
         })
           .then((response) => {
             this.Loading = false;
             if (response.data.code == 200) {
               this.$message({
                 message: response.data.msg,
                 type: "success",
               });
               this.cloud_config.token = response.data.info.sessionToken;
               this.info.module = 'game';
               this.$refs.GameMoGame.CloudStart();
             } else if (response.data.code == 300) {
               this.$confirm(response.data.msg, "提示", {
                 confirmButtonText: "确定",
                 cancelButtonText: "取消",
                 type: "warning",
               })
                 .then(() => {
                   this.Loading = true;
                   this.axios({
                     // 默认请求方式为get
                     method: "post",
                     url: Vue.prototype.config.weburl + "/cloud/ExitCloud",
                     // 传递参数
                     data: {
                       key: this.$route.query.key,
                       token: localStorage.getItem("token"),
                     },
                     responseType: "json",
                   })
                     .then((response) => {
                       this.Loading = false;
                       if (response.data.code == 200) {
                         this.$message({
                           type: "success",
                           message: response.data.msg,
                         });
                       } else {
                         this.$message({
                           message: response.data.msg,
                           type: "warning",
                         });
                       }
                     })
                     .catch((error) => {
                       this.Loading = false;
                       // 请求失败，
                       console.log(error);
                     });
                 })
                 .catch(() => { });
             } else {
               this.$message({
                 message: response.data.msg,
                 type: "warning",
               });
             }
           })
           .catch((error) => {
             this.Loading = false;
             // 请求失败，
             console.log(error);
           });
     },
     //游戏离开
     GameLeave(){
      if (this.Loading == true) {
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.weburl + "/cloud/ExitCloud",
          // 传递参数
          data: {
            key: this.$route.query.key,
            token: localStorage.getItem("token"),
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.$message({
                message: response.data.msg,
                type: "success",
              });
              this.info.module = 'home';
              location.reload();
            } else {
              this.$message({
                message: response.data.msg,
                type: "warning",
              });
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
     },
     //会员购买
     BuyMembers(){
       this.$message({
         message: "会员功能未开放",
         type: "warning",
       });
     },
     Login(){
      this.$refs.LoginGame.LoginShow();
     },
     Personal(){
      this.$refs.PersonalMo.Show();
     },
     //跳转
     url_address(url,types){
       this.statisticalEvent(types);
         window.open(url, "_blank");
     },
     statisticalEvent (type) {
       this.axios({
         // 默认请求方式为get
         method: "post",
         url: Vue.prototype.config.weburl + "/cloud/statisticalEvent",
         // 传递参数
         data: {
           key: this.$route.query.key,
           type: type,
           token: localStorage.getItem("token"),
         },
         responseType: "json",
       })
         .then((response) => {
           console.log(response.data.code);
         })
         .catch((error) => {
           // 请求失败，
           console.log(error);
         });
     },
   },
 };
 </script>
 <style lang="less">
 .el-message {
    min-width: 80% !important;
  }
.el-message-box {
    width: 78% !important;
}
 #root, body, html{
  min-width:0px !important;
}
   *,
   *:before,
   *:after {
     box-sizing: border-box;
     outline: none;
   }
   html {
     height: 100%;
   }
   body {
     height: 100%;
     margin: 0;
     --window-inner-height: 1002px;
     --window-inner-width: 1408px;
   }
   ul,
   li {
     padding: 0;
     list-style: none;
   }
   .flex {
     display: flex;
   }
   .flex.xlr {
     justify-content: space-between;
   }
   .flex.yc {
     align-items: center;
   }
   .flex.xc {
     justify-content: center;
   }
   #app ,#Home{
     height: 100%;
   }
   </style>
 
   